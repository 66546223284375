import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './components/App';
import NavBar from "./components/NavBar";

ReactDOM.render(<React.StrictMode>
    {/*<NavBar/>*/}
    <App/>
</React.StrictMode>, document.getElementById('root'));

