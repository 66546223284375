/*eslint no-unused-vars: "off"*/
import '../styles/App.css';
import React, {useEffect, useReducer, useState} from 'react';
import axios from 'axios'
import {Helmet} from "react-helmet";
import CountrySelector from "./CountrySelector";

const initialState = {
    start_date: new Date().toISOString().split('T')[0],
    work_days: 0,
    options: 0,
    result: '',
    country_name: '',
    country_code: ''
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'field':
            return {
                ...state,
                [action.field]: action.payload
            }
        case 'country':
            return {
                ...state,
                country_name: action.payload[0],
                country_code: action.payload[1]
            }
        case 'result':
            return {
                ...state,
                result: action.payload,
            }
        default:
            return state
    }

}


const App = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [changeCountry, setChangeCountry] = useState(false)

    const [showBanner, setShowBanner] = useState(true)

    const getResult = (start_date, work_days, options, state) => {
        let query = `?start_date=${start_date}&work_days=${work_days}&options=${options}&state=${state}`
        axios.get('/api/v1/get_result' + query)
            .then(data => {
                if (data.data)
                    dispatch({type: 'result', payload: new Date(data.data)})
            })
            .catch(e => {
                console.log(e)
            })
    }

    useEffect(() => {
        if (state.start_date && state.work_days > 0 && state.country_code) {
            getResult(state.start_date, state.work_days, state.options, state.country_code)
        }
    }, [state.work_days, state.options, state.start_date, state.country_code])

    useEffect(() => {
        let data = window.location.pathname
        if (data.includes("/ct/")) {
            let parts = data.split("/")
            let name = decodeURIComponent(parts[2])
            let cKey = parts[3]
            dispatch({type: 'country', payload: [name, cKey]})
        } else {
            axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                dispatch({type: 'country', payload: [data.country_name, data.country_code]})
            }).catch((error) => {
                console.log(error);
            });
        }

    }, []);


    return (<>
            <Helmet>
                <title>Business 2 Days - Business Days
                    Calculator {state.country_name ? "in " + state.country_name : ""} | Workdays Calculator | Working Days
                    Calculator</title>
                <meta name="geo.region" content={state.country_code}/>
            </Helmet>
            <div className="container">
                <CountrySelector show={changeCountry} setShow={setChangeCountry} dispatch={dispatch} state={state}/>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                     className="content">
                    <div id={"calc"} style={{
                        width: "50%",
                        color: "white",
                        zIndex: 2,
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "start"
                    }}>
                        <div style={{fontSize: "55px", fontWeight: 800}}>Business Days Calculator</div>
                        <p style={{width: "calc(80% - 20px)"}}>Convert from business or workdays to calendar
                            days {state.country_name ? "in " + state.country_name : ""}</p>
                        <div  className={"days"} style={{
                            flexDirection: "row-reverse",
                            width: "calc(80% - 20px)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start"
                        }}>
                            <div id={"start"} style={{width: "30%"}}>
                                <h3 style={{marginTop: "35px"}}>Start Date 📅</h3>
                                <input style={{width: "100%"}} onChange={(e) => dispatch({
                                    type: "field",
                                    field: 'start_date',
                                    payload: e.target.value
                                })}
                                       value={state.start_date} className="input-text" type="date"/>

                            </div>
                            <div id={"count"} style={{width: "70%"}}>
                                <h3 style={{marginTop: "35px"}}>How Many Workdays? 🏋️</h3>
                                <input style={{width: "100%"}} min={1} max={365}
                                       onChange={(e) => dispatch({
                                           type: "field",
                                           field: 'work_days',
                                           payload: e.target.value
                                       })}
                                       value={state.work_days} className="input-text" type="number"/>

                            </div>

                        </div>

                        <h3>Non Workdays Are</h3>
                        <select className={"input-text"}
                                onChange={(e) => dispatch({type: "field", field: 'options', payload: e.target.value})}
                                value={state.options}>
                            <option value={0}>Weekends and Holidays</option>
                            <option value={1}>Only Weekends</option>
                            <option value={2}>Only Holidays</option>
                        </select>
                    </div>
                    <div id={"target"} className={"float-background"}
                         style={{width: "50%", height: "max-content", textAlign: "left"}}>


                        <div id={"tlabel"} style={{color: "#1155a6", fontSize: "30px", fontWeight: "600"}}>Target Date</div>
                        <div id={"res"} style={{
                            color: "white",
                            fontWeight: "600",
                            fontSize: "55px"
                        }}>{state.result ? state.result.toDateString() : new Date().toDateString()}</div>
                        <br/>
                        <h3 style={{color: "white", marginBottom: "5px", fontSize: "18px"}}>Calendar Days Count:</h3>
                        <div style={{color: "white", fontSize: "16px", fontWeight: 300}}>{state.result ?
                            <div>{dateDifference(new Date(state.start_date), state.result)} Days</div> :
                            <div>{dateDifference(new Date(), new Date())} Days</div>}</div>


                    </div>


                </div>

                {state.country_code ?
                    <div>{
                        (<div style={{color: 'white', display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}><span>Selected country
                                is <strong>{state.country_name}</strong>.</span>

                                <button style={{color:"white"}} className={"btn btn-link"} onClick={e=>setChangeCountry(true)}>Change Country</button>
                            </div>
                        )
                    }

                    </div>

                    : ''}<br/>

            </div>
            <div hidden={!showBanner} style={{position:"fixed", bottom:0, width:"100vw", background:"rgba(255,255,255,.75)", height:"50px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <a style={{fontWeight:"bold", fontSize:"18px"}} href={"https://rapidapi.com/Morax/api/business-days-work-days-calculator"}>
                    Try our new API for developers
                </a>
                <svg style={{position:"absolute", right:15, cursor:"pointer"}} onClick={e=>setShowBanner(false)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                </svg>
            </div>
        </>
    );
}

function dateDifference(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

export default App;
